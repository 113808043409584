import {isEmpty, isNil} from 'lodash-es';
import {DateTime} from 'luxon';
import {useI18n} from 'vue-i18n';

export const quantityFormat = (val, options: Partial<Intl.NumberFormatOptions> = {}) => Intl.NumberFormat('en', {
  ...options,
}).format(val);

export const useFilters = () => {
  const i18n = useI18n();

  const numberFormat = (val, options: Partial<Intl.NumberFormatOptions> = {}) => {
    if (isNil(val)) {
      return '';
    }

    return Intl.NumberFormat(i18n.locale.value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    }).format(val);
  };

  const currencyFormat = (val, currency = ',-') => {
    if (currency === ',-') {
      const number = Intl.NumberFormat(i18n.locale.value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);

      return isEmpty(number) ? '' : `${number}${currency}`;
    }

    return Intl.NumberFormat(i18n.locale.value, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency,
    }).format(val);
  };

  const dateFormat = (date, {format = 'dd. MM. y', locale = i18n.locale.value} = {}) => {
    return !isNil(date) ? DateTime
      .fromJSDate(date)
      .setLocale(locale)
      .toFormat(format) : null;
  };

  const dateTimeFormat = (date, {format = 'dd. MM. y HH:mm', locale = i18n.locale.value} = {}) => {
    return dateFormat(date, {format, locale});
  };

  const timeFormat = (date, {format = 'HH:mm', locale = i18n.locale.value} = {}) => {
    return dateFormat(date, {format, locale});
  };

  const booleanFormat = (val) => {
    return i18n.t(`general.boolean.${val}`);
  };

  const booleanActiveFormat = (val) => {
    return i18n.t(`general.booleanActive.${val}`);
  };

  const isNilFormat = (val, string = '') => {
    return isNil(val) ? string : val;
  };

  const stringifyFormat = (...args: Parameters<typeof JSON.stringify>) => {
    return JSON.stringify(...args);
  };

  return {
    numberFormat,
    currencyFormat,
    dateFormat,
    dateTimeFormat,
    timeFormat,
    booleanFormat,
    booleanActiveFormat,
    isNilFormat,
    stringifyFormat,
  };
};
