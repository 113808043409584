import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalDetachedClose = _resolveComponent("ModalDetachedClose")!
  const _component_MarkdownContent = _resolveComponent("MarkdownContent")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalDetachedButtons = _resolveComponent("ModalDetachedButtons")!

  return (!_ctx.transitionLeaving)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ModalDetachedClose, { onClose: _ctx.onReject }, null, 8, ["onClose"]),
        (_ctx.coreStore.confirmContent.value && _ctx.coreStore.confirmContent.value.message)
          ? (_openBlock(), _createBlock(_component_MarkdownContent, {
              key: 0,
              class: _normalizeClass(['markdown-content text-lg', _ctx.typeClasses]),
              content: _ctx.coreStore.confirmContent.value.message
            }, null, 8, ["class", "content"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ModalDetachedButtons, {
          single: !_ctx.hasRejectFn
        }, {
          default: _withCtx(() => [
            (_ctx.hasRejectFn)
              ? (_openBlock(), _createBlock(_component_Button, _normalizeProps({
                  key: 0,
                  class: "btn-outline-red",
                  [(_ctx.$test.confirm) || ""]: 'reject',
                  onClick: _ctx.onReject
                }), {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "rejectBtn", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.$t('action.reject')), 1)
                    ])
                  ]),
                  _: 3
                }, 16, ["onClick"]))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, _normalizeProps({
              class: {
          'btn-outline-green': _ctx.hasRejectFn,
          'btn-green': !_ctx.hasRejectFn
        },
              [(_ctx.$test.confirm) || ""]: _ctx.hasRejectFn ? 'confirm' : 'continue',
              onClick: _ctx.onConfirm
            }), {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "confirmBtn", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.hasRejectFn ? _ctx.$t('action.confirm') : _ctx.$t('action.continue')), 1)
                ])
              ]),
              _: 3
            }, 16, ["class", "onClick"])
          ]),
          _: 3
        }, 8, ["single"])
      ]))
    : _createCommentVNode("", true)
}