import {CustomerState} from '@api/posMiddlewareCustomerLoyalty/constants/customerState';
import {Sex} from '@api/posMiddlewareCustomerLoyalty/constants/sex';

export default {
  cs: {
    customer: {
      detail: {
        title: '#{customerNumber}',
        tabs: {
          transactions: {
            title: 'Transakce',
            actions: {
              add: 'Přidat transakci',
            },
          },
          cards: {
            title: 'Karty',
          },
          attributes: {
            title: 'Atributy',
          },
          contacts: {
            title: 'Kontakty',
          },
        },
        actions: {
          generateAndSendPin: {
            title: 'Vygenerovat a odeslat PIN',
            confirm: 'Opravdu chcete vygenerovat a odeslat PIN?',
            smsTemplate: 'Dobry den, vas novy pin pro zakaznicky system GECO je: {pin}',
          },
          updatePhone: {
            title: 'Změnit telefon',
          },
          delete: {
            title: 'Smazat zakaznika',
            confirm: 'Opravdu chcete smazat zakaznika? Tato akce je nevratná.',
          },
        },
      },
      grid: {
        title: 'Zákazníci',
        filters: {
          club: 'Klub',
          phone: 'Telefon',
          firstNameAndLastName: 'Jméno a příjmení',
          customerNumber: 'Číslo zákazníka',
          cardNumber: 'Číslo zákaznické karty',
          email: 'E-mail',
          birthdateFrom: 'Narození od',
          birthdateTo: 'Narození do',
          lastTransactionDateFrom: 'Poslední transakce od',
          lastTransactionDateTo: 'Poslední transakce do',
          state: 'Status',
          includeDeleted: 'Zahrnout smazané',
        },
        advancedFilters: {
          title: 'Filtrovat zákazníky',
          placeholders: {
            customerNumber: 'Zadejte číslo zákazníka',
            cardNumber: 'Zadejte číslo zákaznické karty',
            club: 'Vyberte klub',
            firstNameAndLastName: 'Zadejte jméno a příjmení',
            phone: 'Zadejte telefon',
            email: 'Zadejte e-mail',
            state: 'Vyberte status zákazníka',
          },
        },
        bulkDeleteAttributes: 'Smazat atributy',
        bulkInsertAttributes: 'Vložit atributy',
        importAttributes: 'Importovat atributy',
      },
      fields: {
        customerNumber: 'Číslo zákazníka',
        firstNameAndLastName: 'Jméno',
        sex: 'Pohlaví',
        sexEnum: {
          [Sex.Female]: 'Žena',
          [Sex.Male]: 'Muž',
          [Sex.Unknown]: 'Neznámý',
        },
        phone: 'Telefon',
        email: 'E-mail',
        birthdate: 'Narození',
        points: 'Body',
        lastTransactionDate: 'Posl. transakce',
        state: 'Status',
        stateEnum: {
          [CustomerState.InVerification]: 'Čeká na ověření',
          [CustomerState.RegistrationComplete]: 'Ověřen',
          [CustomerState.Deleted]: 'Smazán',
          [CustomerState.Unknown]: 'Stav neznámý',
        },
        activeCardNumber: 'Karta zákazníka',
        gdprConsentDate: 'Souhlas s GDPR',
        gdprConsentPlace: 'Místo souhlasu s GDPR',
        createdDate: 'Vytvořen',
        deletedDate: 'Smazán',
        phoneVerificationDate: 'Telefon ověřen',
        emailVerificationDate: 'E-mail ověřen',
        customerCards: 'Karty zákazníka',
      },
      form: {
        bulkDeleteCustomerAttributes: {
          title: 'Smazat atributy',
          success: 'Úspěšně smazáno {count} atributů.',
          nothingDeleted: 'Nebyly smazány žádné atributy.',
        },
        bulkInsertCustomerAttributes: {
          title: 'Vložit atributy',
          success: 'Úspěšně vloženo {count} atributů.',
          notFoundCustomerNumbers: 'Následující čísla zákazníků nebyla nalezena: {customerNumbers}.',
          customerNumbersWithExistingAttributes: 'Následující čísla zákazníků již mají atributy: {customerNumbers}.',
        },
        importAttributes: {
          title: 'Importovat atributy',
          success: 'Úspěšně importováno {count} atributů.',
          notFoundCustomerNumbers: 'Následující čísla zákazníků nebyla nalezena: {customerNumbers}.',
          customerNumbersWithExistingAttributes: 'Následující čísla zákazníků již mají atributy: {customerNumbers}.',
          description: 'Importovat atributy zákazníků z CSV souboru. Soubor musí obsahovat sloupce \'Číslo zákazníka\', \'Kód\' a \'Hodnota\'.',
          example: {
            title: 'Příklad',
            value: 'CustomerNumber,AttributeKey,AttributeValue\n1234,attr1,value1\n1234,attr2,value2\n5678,attr1,value3',
          },
        },
      },
    },
  },
};
