
import MarkdownIt from 'markdown-it';
import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'MarkdownContent',
  props: {
    content: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const md = MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    });

    const renderContent = computed(() => {
      if (!props.content) {
        return '';
      }

      try {
        return md.render(props.content);
      } catch (error) {
        console.error('Error rendering markdown content:', error);
        return '';
      }
    });


    return {
      renderContent,
    };
  },
});
