
import Button from '@ui/components/button/Button.vue';
import MarkdownContent from '@ui/components/MarkdownContent.vue';
import ModalDetachedButtons from '@ui/components/modal/ModalDetachedButtons.vue';
import ModalDetachedClose from '@ui/components/modal/ModalDetachedClose.vue';
import {useTransitions} from '@ui/helpers/transitions';
import {computed, defineComponent} from 'vue';
import {useCoreStore} from '../../store/CoreStore';
import {InteractionLevel} from '../../types';
import {useConfirmContent} from './content';

export default defineComponent({
  name: 'ModalConfirmContentMarkdown',
  components: {
    Button,
    ModalDetachedButtons,
    ModalDetachedClose,
    MarkdownContent,
  },
  setup() {
    const coreStore = useCoreStore();
    const {
      type,
      hasRejectFn,
      onReject,
      onConfirm,
    } = useConfirmContent();
    const {transitionLeaving} = useTransitions();

    const typeClasses = computed(() => {
      switch (type.value) {
        case InteractionLevel.error:
          return 'text-red';
        case InteractionLevel.warning:
          return 'text-orange';
        case InteractionLevel.info:
          return 'text-blue';
        case InteractionLevel.success:
          return 'text-green';
        case InteractionLevel.default:
        default: return 'text-black';
      }
    });

    return {
      type,
      hasRejectFn,
      onReject,
      onConfirm,
      coreStore,
      transitionLeaving,
      typeClasses,
    };
  },
});
