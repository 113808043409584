export function isDev(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function isProd(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function isTestcafe(): boolean {
  const metaTag = document.head.querySelector('meta[name="testcafeEnvironment"]');
  return metaTag && metaTag.getAttribute('content') === 'runtime';
}
