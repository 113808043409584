import {includes, map} from 'lodash-es';
import {RouteRecordRaw} from 'vue-router';
import {useFeatureStore} from '../Core/store/FeatureStore';
import routes from './routes';
import {useAuthStore} from './store/AuthStore';
import translations from './translations';

/**
 * Defines what this module needs
 */
export type RequiredContext = import('@ui/plugins/modules').ModuleSystem & {
  appId: any,
  preloadHooks: any[],
}

/**
 * Define what this module provides thru context
 */
export type Context = RequiredContext & {
}

export function registerAuthModule({apiIdentifyMe, aclMap = null}) {
  return async function(ctx: RequiredContext) {
    await ctx.registerTranslations(translations);

    await ctx.registerRoutes(routes);

    const authStore = useAuthStore(ctx.appId);
    const featureStore = useFeatureStore();

    authStore.setAppSpecificApi('identifyMe', apiIdentifyMe);

    authStore.setAclMap(aclMap);

    await ctx.registerHookBeforeEach(async (to, from, next) => {
      try {
        if (!includes(map(routes as RouteRecordRaw[], 'name'), to.name)) {
          await authStore.ensureIdentityUser();
        }
        next();
      } catch (e) {
        console.error(e);
        next({name: 'redirect-login'});
      }
    });

    await ctx.registerHookBeforeEach(async (to, from, next) => {
      const aclKeyForAccess = (to?.meta?.aclKeyForAccess ?? null) as any;

      if (!aclKeyForAccess) {
        return next();
      }

      if (authStore.hasAccess(aclKeyForAccess)) {
        return next();
      }

      next({name: 'unauthorized'});
    });

    await ctx.registerHookBeforeEach(async (to, from, next) => {
      const featureFlagForAccess = (to?.meta?.featureFlagForAccess ?? null) as any;

      if (!featureFlagForAccess) {
        return next();
      }

      if (featureStore.hasActiveFeature(featureFlagForAccess)) {
        return next();
      }

      next({name: 'unauthorized'});
    });
  };
}
