export enum InteractionLevel {
  error = 'error',
  warning = 'warning',
  info = 'info',
  success = 'success',
  default = 'default'
}

export enum ConfirmTemplates {
  default = 'confirmOrAlert',
  markdown = 'markdown',
}

export interface IModal {
  id: string,
  zIndexBase: number,
  zIndexOffset: number,
  silenceCloseListeners: boolean,
  spawnedAtRoute: any,
  closeCallback: (positive: boolean) => void,
}

export interface INotification {
  id: string,
  zIndexBase: number,
  zIndexOffset: number,
  closeCallback: () => void,
}
