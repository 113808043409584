import {Entity} from '@designeo/apibundle-js';
import {injectFormContext} from '@designeo/vue-forms/src/Form/Form';
import {Store} from '@designeo/vue-helpers';
import {inputProps} from '@ui/components/input/input';
import {useAsync} from '@ui/helpers/async';
import {isValidByFlags} from '@ui/helpers/input';
import {PersistentStore} from '@ui/helpers/store';
import {useCoreStore} from '@ui/modules/Core/store/CoreStore';
import {
  isFunction,
  mapValues,
  some,
  throttle,
} from 'lodash-es';
import {
  computed,
  inject,
  provide,
  Ref,
  ref,
  watch,
} from 'vue';
import * as yup from 'yup';
import {createValidationsBridge, IValidationsBridge} from './validations';

export enum FormContainerSectionLayout {
  single = 'single',
  base = 'base',
  compressed = 'compressed',
  detailed = 'detailed',
}

export function useForm <T = Entity<any, any>>(
  {
    entity,
    validations = () => yup.object({}),
    onEntityDraftUpdate = null,
    methods = {},
  }: {
    entity: (() => T) | T,
    validations?: ({bridge}: {bridge: IValidationsBridge}) => yup.AnyObjectSchema,
    onEntityDraftUpdate?: (entity: T) => void,
    methods?: {[key: string]: Function},
  },
) {
  const entityDraft = ref(null) as Ref<T>;
  const errors = ref({});
  const formRef = ref(null);
  const wrappedMethods = mapValues(methods, useAsync);

  watch(entityDraft, () => {
    if (onEntityDraftUpdate) {
      onEntityDraftUpdate(entityDraft.value);
    }
  }, {deep: true});

  const onUpdateEntityDraft = (value) => {
    entityDraft.value = value;
  };

  const bridge = createValidationsBridge({
    validate: async () => {
      if (!formRef.value?.formRef) {
        return;
      }

      return await validate();
    },
  });

  const toProps = () => {
    return {
      'editEntity': (isFunction(entity) ? entity() : entity) ?? undefined,
      'entityDraft': entityDraft.value ?? undefined,
      'onUpdate:entityDraft': onUpdateEntityDraft,
      'rulesSchema': validations({bridge}),
      'errors': errors.value,
      'onUpdate:errors': (value) => {
        errors.value = value;
      },
      'ref': (el) => {
        formRef.value = el;
      },
    };
  };

  const submit = async () => {
    await formRef.value.formRef.submitForm();
  };

  const validate = async () => {
    return await formRef.value.formRef.validate();
  };

  const isLoading = computed(() => {
    return some(wrappedMethods, (method) => method.running.value);
  });

  return {
    entity: entityDraft,
    toProps,
    formRef,
    submit,
    errors,
    validate,
    methods: wrappedMethods,
    isLoading,
  };
}


const FormInputContext = 'FormInputContext';

type FormInputApi<> = {
  field: string,
  readonly: boolean,
  disabled: boolean,
}

export const injectFormInputContext = () => {
  return <FormInputApi>inject(FormInputContext, {
    field: null,
    readonly: null,
    disabled: null,
  });
};

export const provideFormInputContext = (field: string) => {
  const {disabled, readonly} = injectFormContext();
  const context: FormInputApi = {
    field,
    disabled,
    readonly,
  };

  provide(FormInputContext, context);
};

export const useInput = <IP extends typeof inputProps>(props: {[K in keyof IP]: any}) => {
  const {
    disabled: formDisabled,
    readonly: formReadonly,
    field,
  } = injectFormInputContext();
  const innerFocused = ref(false);

  const valid = computed(() => isValidByFlags(props.flags));

  const computedDisabled = computed(() => {
    return props.disabled ?? formDisabled ?? false;
  });

  const computedReadonly = computed(() => {
    return props.readonly ?? formReadonly ?? false;
  });

  const disabled = computed(() => {
    return computedDisabled.value || computedReadonly.value;
  });

  const focused = computed({
    get: () => {
      if (disabled.value) {
        return false;
      }

      return innerFocused.value;
    },
    set: (value) => {
      innerFocused.value = value;
    },
  });

  return {
    field,
    valid,
    focused,
    disabled,
    computedDisabled,
    computedReadonly,
  };
};


export const useFormPage = <
  T extends Entity<any, any>,
  S extends (Store<any> | PersistentStore<any>) = null
>(id, fetch: (id) => (T | Promise<T>), {store = null}: {store?: S} = {}) => {
  const coreStore = useCoreStore();
  const entity = ref(null) as Ref<T>;
  const isLoading = ref(false);
  const fetchError = ref(null);
  const isFormValid = ref<boolean>(true);

  const fetchDebounced = throttle(async () => {
    try {
      coreStore.setLoader(true);
      isLoading.value = true;
      entity.value = await fetch(id);
      fetchError.value = null;
    } catch (e) {
      fetchError.value = e;
      console.error(e);
    } finally {
      coreStore.setLoader(false);
      isLoading.value = false;
    }
  }, 1000);

  watch(() => id, async () => {
    await fetchDebounced();
  }, {immediate: true});

  return {
    entity,
    isLoading,
    fetchError,
    store,
    isFormValid,
  };
};
