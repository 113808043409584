import '@ui/style/main.css';
import {emitTestEvent} from '@ui/helpers/testEvent';
import {createApp, createSSRApp} from 'vue';
import {isTestcafe} from './env';


export async function withClientAppContext <T extends (...args: any[]) => Promise<any>>(createContext: T) {
  const ctx = await createContext({
    createApp,
    ssrState: window['__SSR_STATE__'],
  });

  const ssrRendered = !!window['__SSR_STATE__'];

  ctx.app.use(ctx.router);

  await ctx.router.isReady();

  ctx.ensureStartupClose();

  ctx.app.mount('#app', ssrRendered);

  ctx.app.config.warnHandler = (msg, vm, trace) => {
    if (!isTestcafe()) {
      console.warn(`[Vue warn]: ${msg}${trace ? '\n' + trace : ''}`);
    }
  };

  for (const hook of ctx.preloadHooks) {
    await hook();
  }

  emitTestEvent('ready', '*');
}

export async function withServerAppContext <T extends (...args: any[]) => Promise<any>>(
  createContext: T,
  ssrContext: any,
) {
  const {url} = ssrContext;
  const ctx = await createContext({
    createApp: createSSRApp,
  });

  if (ctx.isSplashScreen) return;

  ctx.router.push(url);
  await ctx.router.isReady();

  ctx.ensureStartupClose();

  ssrContext['state'] = JSON.stringify(ctx.ssrState.serialize());
  return ctx.app;
}
